import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["div", "checkbox"]

  connect() {
    this.setVisibility()
  }

  setVisibility(){
    if(this.checkboxTarget.checked) {
      this.divTarget.style.display = "block"
    } else {
      this.divTarget.style.display = "none"
    }
  }
}
