import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["id", "total", "accept", "form", "radioTrue", "radioFalse"]

  setData(event){
    this.idTargets.forEach((target) => {
      target.value = event.params.id
    })
    this.totalTargets.forEach((target) => {
      target.innerText = event.params.total
    })
    this.acceptTargets.forEach((target) => {
      target.value = event.params.accept
    })
    this.formTargets.forEach((target) => {
      target.style.display = event.params.accept === 1 ? "none" : "block"
    })
    if(event.params.radio) {
      this.radioTrueTargets.forEach((target) => {
        target.checked = true
      })
    } else {
      this.radioFalseTargets.forEach((target) => {
        target.checked = true
      })
    }
  }
}
