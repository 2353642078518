import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["field", "div", "allowance"];

    static values = {
        allowances: Array
    }

    connect() {
        //console.log("DatalistValidationController connect")
    }

    validate() {
        const opts = this.fieldTarget.list.options;
        const vals = [...opts].map(el => el.value);
        if(vals.includes(this.fieldTarget.value)){
            this.fieldTarget.classList.remove("is-invalid")
            return true
        } else {
            this.fieldTarget.classList.add("is-invalid")
            return false
        }

    }

    updateAllowance(){
        try{
            if(this.allowanceTarget.value.trim()){
                return
            }
            // get correct allowance from array and selected value
            this.allowancesValue.forEach(item => {
                if(item[0] === this.fieldTarget.value){
                    this.allowanceTarget.value = (parseInt(item[1])).toFixed(2)
                }
            })
        } catch(e){
        }
    }

    validateAndUpdateAllowance(){
        if(this.validate()){
            this.updateAllowance()
        }
    }

    setAllowanceSelected(){
        this.allowanceTarget.select();
    }
}