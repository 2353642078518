// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import { pollForSessionTimeout } from "./session_timeout_poller"
import * as bootstrap from "bootstrap"
window.Modal = bootstrap.Modal;
window.Tooltip = bootstrap.Tooltip;

import '@fortawesome/fontawesome-free/js/all'

Turbo.session.drive = false

document.addEventListener("DOMContentLoaded", function () {
    window.setTimeout(pollForSessionTimeout, (1000))
});


const reloadTooltips = function(){
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))

    tooltipTriggerList.forEach(function(tooltipTriggerEl){
        let tooltip = Tooltip.getInstance(tooltipTriggerEl);
        if(tooltip){
            tooltip.dispose();
        }
    });
    tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new Tooltip(tooltipTriggerEl)
    })
}
document.addEventListener("turbo:load", function () {
    reloadTooltips()
});
document.addEventListener("turbo:render", function () {
    reloadTooltips()
});

