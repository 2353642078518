import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button", "checkbox", "tooltip"]

  connect() {
    this.setState()
  }

  setState(){
    const tooltip = new Tooltip(document.getElementById(this.tooltipTarget.id))
    if(this.checkboxTarget.checked) {
      this.buttonTarget.classList.remove("disabled")
      tooltip.disable()
    } else {
      this.buttonTarget.classList.add("disabled")
      tooltip.enable()
    }
  }
}
