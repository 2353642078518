import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["select", "input"]

    connect(){
        this.inputs = this.inputTargets
        this.selects = this.selectTargets
        this.loadFilter()
    }


    // persists the values of select and input to the sessionstorage
    persistFilter(){
        for(var item of this.selects){
            sessionStorage.setItem(`${item.id}_value`, item.value)
        }
        for(var item of this.inputs){
            sessionStorage.setItem(`${item.id}_name`, item.name)
            sessionStorage.setItem(`${item.id}_value`, item.value)
        }
    }

    // loads values of selects and inputs from sessionstorage and inserts them to filter component
    loadFilter() {
        for(var item of this.selects){
            let stored = sessionStorage.getItem(`${item.id}_value`)
            if(stored) {
                item.value = stored
            }
        }
        for(var item of this.inputs){
            let stored = sessionStorage.getItem(`${item.id}_name`)
            if(stored) {
                item.name = stored
            }
            stored = sessionStorage.getItem(`${item.id}_value`)
            if(stored) {
                item.value = stored
            }
        }
    }

    // removes all values of selects and inputs from sessionstorage
    removeFilter(){
        for(var item of this.selects){
            sessionStorage.removeItem(`${item.id}_value`)
        }
        for(var item of this.inputs){
            sessionStorage.removeItem(`${item.id}_name`)
            sessionStorage.removeItem(`${item.id}_value`)
        }
    }


}

