const sessionTimeoutPollFrequency = 60

export function pollForSessionTimeout() {
    let request = new XMLHttpRequest()
    request.onload = function (event){
        var status = event.target.status
        if(status === 200) {
            var timeout = 0
            try{
                timeout = event.target.response.timeout
            } catch(e) {}

            try{
                const progressBar = document.querySelector('.progress-bar');
                const progress = (timeout / 1800) * 100 // autologout time is 30min=1800s
                progressBar.style.width = `${progress}%`
            }catch(e) {}

            // set tooltip
            try{
                const tooltipElement = document.querySelector('.progress-bar-wrapper');
                tooltipElement.setAttribute('data-bs-original-title', event.target.response.title)
            }catch(e) {}

            const noLogoutFromPaths = ['/users/sign_in', '/users/password/new', '/users/password/edit', '/users/invitation/accept', '/users/invitation', '/registration']
            if(timeout <= 0 && !noLogoutFromPaths.includes(window.location.pathname)){
                // redirect to root or login?
                window.location.replace("/")
            }
        }
    }
    request.open('GET', '/check_session_timeout', true)
    request.responseType = 'json'
    request.send()
    setTimeout(pollForSessionTimeout, (sessionTimeoutPollFrequency * 1000))
}


