import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form"];
  connect() {
    console.log("AutoSubmitFormController connect")
  }

  submit() {
    console.log("AutoSubmitFormController")
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this._submit();
    }, 300);
  }

  _submit() {
    console.log("Auto-Submitting Form....")
    this.formTarget.requestSubmit();
  }
}