import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["select", "input"]

    connect(){
    }

    // sets the selected filter method (equals, contains)
    updateInputName(e) {
        this.inputTarget.name = `q[${e.target.value}]`
    }



}

