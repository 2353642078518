import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["files"];

  static values = {
    modalId: String,
    modalFiletypeId: String
  }
 
  
  addFile(event) {
    //console.log(this.modalIdValue)
    // references for new elements to be created
    const originalInput = event.target 
    const originalParent = originalInput.parentNode

    // first of all, validate max file size 3 MiB 3*1024*1024
    if(originalInput.files[0].size > 3*1024*1024) {
      // console.log("file too big: ", originalInput.files[0].size)
      originalInput.value = ""
      if(this.modalIdValue != null){
        const modal = new Modal(document.getElementById(this.modalIdValue))
        modal.show()
      }
      return
    }
    // validate file type
    const filetype = originalInput.files[0].type
    if(!(filetype === "image/jpeg" || filetype === "application/pdf")) {
      originalInput.value = ""
      if(this.modalFiletypeIdValue != null){
        const modal = new Modal(document.getElementById(this.modalFiletypeIdValue))
        modal.show()
      }
      return
    }

    // create element that contains input element
    const selectedFile = document.createElement("div")
    selectedFile.className = "selected-file"
    selectedFile.append(originalInput)

    // add a X button to delete
    var deleteNode = document.createElement("button")
    deleteNode.className = "btn-close"
    deleteNode.addEventListener("click", this.onClickX)
    selectedFile.appendChild(deleteNode)

    // add a description input field
    var descriptionNode = document.createElement("select")
    descriptionNode.name = event.params.nodename + "[doc_description][]"
    
    // generate selections
    var options = event.params.options || []
    var el = document.createElement("option")
    el.text = event.params.prompt || ""
    el.value = ""
    el.selected = true
    descriptionNode.add(el)

    for(var i = 0; i < options.length; i++){
      var opt = options[i]
      var el = document.createElement("option")
      el.value = opt?.[0]
      el.text = opt?.[1]
      if(event.params.hidden) el.selected=true
      descriptionNode.add(el)
    }
    if(event.params.hidden) descriptionNode.hidden = true
    selectedFile.appendChild(descriptionNode)

    // create label with name of selected file
    var labelNode = document.createElement("label")
    var textElement = document.createTextNode(originalInput.files[0].name)
    labelNode.appendChild(textElement)
    selectedFile.appendChild(labelNode)

    
    // add input element that triggered this event to the list of selected items
    this.filesTarget.append(selectedFile)

    // create new input element for multiple file uploads
    const newInput = originalInput.cloneNode()

    // clear filelist from new element (some browsers keep filelist when cloning nodes)
    newInput.value = ""

    // add new input to the dom
    originalParent.append(newInput)
  }

  onClickX(event) {
    event.target.parentNode.remove()
  }

}