import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["div"]

  initialize() {
    this.resizeHandler = this.handleResize.bind(this);
  }

  connect() {
    if(this.isFilterActive()){
      this.toggleVisibility()
    } else {
      this.removeFilters()
    }
    window.addEventListener("resize", this.resizeHandler);
  }

  disconnect() {
    window.removeEventListener("resize", this.resizeHandler);
  }

  toggleVisibility(){
    this.divTarget.classList.toggle("expanded");
    this.handleResize()

    // find closest input element with class "filter_input"
    const closestInput = this.divTarget.querySelector(".filter_input");
    if(closestInput){
      closestInput.focus();
    }
  }

  handleResize() {
    const div = this.divTarget;
    // calculate the content height
    const contentHeight = div.scrollHeight + "px";
    // set the max-height dynamically
    div.style.maxHeight = div.classList.contains("expanded") ? contentHeight : 0;
  }

  isFilterActive(){
    // has non-empty value in sessionStorage?
    const values = []
    const keys = Object.keys(sessionStorage)
    for(const key of keys){
      if(key.endsWith("_value") && !key.endsWith("_select_value")){
        const value = sessionStorage.getItem(key)
        values.push(value)
      }
    }
    const sessionHasFilterValue = values.some(value => value !== null && value!==undefined && value!=="")
    // does url contain filter params?
    const currentUrl = window.location.href;
    const queryParams = new URLSearchParams(currentUrl)
    let urlContainsFilter = false
    for(const value of queryParams.keys()){
      if(value.includes("q[")){
        urlContainsFilter = true
        break
      }
    }
    return urlContainsFilter && sessionHasFilterValue
  }

  removeFilters(){
    const keys = Object.keys(sessionStorage)
    for(const key of keys){
      if(key.startsWith("q_") && (key.endsWith("_name") || key.endsWith("_select_value") || key.endsWith("_value"))){
        sessionStorage.removeItem(key)
      }
    }
  }

}
